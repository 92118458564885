import React from "react";
import "./themes/default/main.scss";
import {ChatPage} from "./components/chat/ChatPage";
import {StartPage} from "./components/pages/StartPage";
import { Routes, Route } from "react-router-dom";
import {useSetTheme} from "./hooks/useSetTheme";
import {ContactPage} from "./components/pages/ContactPage";
import {FridgesPage} from "./components/pages/FridgesPage";
import {WashingMachinesPage} from "./components/pages/WashingMachinesPage";
import {DishwashersPage} from "./components/pages/DishwashersPage";
import {TermsAndConditionsPage} from "./components/pages/TermsAndConditionsPage";
import {PrivacyPolicyPage} from "./components/pages/PrivacyPolicyPage";
import {HelpPage} from "./components/pages/HelpPage";

function App() {
    
  useSetTheme();
    
  return (
    <div className="w-100 h-100 overflow-hidden position-relative">
        <Routes>
            <Route path="/" element={<StartPage />}/>
            <Route path="/chat" element={<ChatPage />}/>
            <Route path="/lodowki" element={<FridgesPage />} />
            <Route path="/pralki" element={<WashingMachinesPage />} />
            <Route path="/zmywarki" element={<DishwashersPage />} />
            <Route path="/kontakt" element={<ContactPage />} />
            <Route path="/regulamin" element={<TermsAndConditionsPage />} />
            <Route path="/polityka-prywatnosci" element={<PrivacyPolicyPage />} />
            <Route path="/pomoc" element={<HelpPage />} />
        </Routes>
    </div>
  );
}

export default App;
