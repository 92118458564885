import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {UserType} from "../di-chat-adapter";
import type {Theme} from "di-chat-model";

export interface AppState {
  license: string;
  userType: UserType;
  theme: Theme;
  fontSize: string;
  isMaximized: boolean;
}

// Czy klient, czy agent rozpoznajemy po domenie
// Dla każdego typu usera są inne ustawienia theme w storage
/*const who = window.location.hostname === process.env.REACT_APP_WIDGET_DOMAIN ? "klient" : "agent"; 

const defaultFontSize = localStorage.getItem(`di-${who}-font-size`) ?? "1rem";

const getTheme = ():Theme => {

  // Rzutowanie jest, żeby sę kompilator nie czepiał
  // można tak bezpiecznie zrobić bo niżej sprawdzam czy theme istnieje
  const themeFromStorage = (localStorage.getItem(`di-${who}-theme`) ?? "default") as Theme;

  const themes:Theme[] = ["default", "mono", "hicontrast"];
  
  if ( themes.indexOf(themeFromStorage) !== -1 ) {
    return themeFromStorage;
  } else {
    return "default";
  }
  
}*/

const initialState: AppState = {
  license: "",
  userType: "user",
  theme: "default",
  fontSize: "1rem",
  isMaximized: false
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLicense: (state, action: PayloadAction<string>) => {
      state.license = action.payload;
    },
    setUserType: (state, action: PayloadAction<UserType>) => {
      state.userType = action.payload;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload
    },
    setFontSize: (state, action: PayloadAction<string> ) => {
      state.fontSize = action.payload;
    },
    setIsMaximized: (state, action: PayloadAction<boolean>) => {
      state.isMaximized = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLicense, setUserType, setTheme, setFontSize, setIsMaximized } = appSlice.actions

export default appSlice.reducer