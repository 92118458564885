import {ChatContainer} from "./ChatContainer";
import {nanoid} from "nanoid";
import {AutoDraft, BasicStorage, ChatMessage, IStorage, MessageContentType, ChatProvider} from "@chatscope/use-chat";
import {DiChatAdapter} from "../../di-chat-adapter";
import "./chat-page.scss";
import {useSelector} from "react-redux";
import {isMaximized} from "../../redux/selectors";
import classNames from "classnames";
import {Helmet} from "react-helmet";

const groupIdGenerator = () => nanoid();

const messageIdGenerator = <T extends MessageContentType>(message: ChatMessage<T>) => nanoid();

let adapterRef:DiChatAdapter | undefined = undefined;

// Poprawka podwójnego odtwarzania dźwięków
// W aplikacji docelowej będzie to zrobione w inny sposób, ale wynika to z tego,
// że ma trochę inną konstrukcję niż aplikacja modelowa
const serviceFactory = (storage: IStorage, updateState: () => void) => {
  if ( adapterRef ) {
    adapterRef.disconnect();
  }
  adapterRef = new DiChatAdapter(storage, updateState); 
  return adapterRef;
};

const externalStorage = new BasicStorage({ groupIdGenerator, messageIdGenerator});

export const ChatPage = () => {
  
  const maximized = useSelector(isMaximized);
  
    return (
        <div className="d-flex justify-content-center align-items-stretch chat-page">
            <Helmet>
              <title>Porozmawiaj z nami na czacie</title>
            </Helmet>
            <div className={classNames("chat-page__widget", { "chat-page__widget--maximized": maximized})}>
                <ChatProvider serviceFactory={serviceFactory} storage={externalStorage} config={{
                    typingThrottleTime: 250,
                    typingDebounceTime: 8000,
                    debounceTyping: true,
                    autoDraft: AutoDraft.Save | AutoDraft.Restore
                }} >
                    <ChatContainer />
                </ChatProvider>
            </div>
        </div>
    );
    
}