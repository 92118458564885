import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleHalfStroke, faMinusCircle, faMoon, faPlusCircle, faSun, faWindowMaximize, faWindowMinimize, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {isMaximized, themeSelector} from "../../redux/selectors";
import {useCallback, useEffect, useRef} from "react";
import {Theme} from "di-chat-model";
import {setTheme} from "../../redux/appSlice";
import {useSetFontSize} from "../../hooks/useSetFontSize";
import {Tooltip} from "bootstrap";
import {setIsMaximized} from "../../redux/appSlice";
import "./a11y-toolbox.scss";
import {Link} from "react-router-dom";

export const A11YToolbox = () => {

  const tooltips = useRef<Tooltip[]>([]);
  
  useEffect(() => {
    
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.current = Array.from(tooltipTriggerList).map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl, {
      /* @ts-ignore */ // Bo typowanie ma złą nazwę (brakuje s)
      fallbackPlacements: ["bottom"]
    }));
    
  }, []);

  const hideTooltips = useCallback(() => {
    tooltips.current.forEach(t => t.hide());
  },[tooltips])
  
  const dispatch = useDispatch();

  const maximized = useSelector(isMaximized);
  const currentTheme = useSelector(themeSelector);

  const onSetTheme = useCallback(
    (theme: Theme) => {
      dispatch(setTheme(theme))
    },
    [dispatch],
  );


  const {fontDefault, fontUp, fontDown, fontSizeFloat} = useSetFontSize("1rem", 0.25);

  return (
    <>
    <menu className="d-flex align-items-center m-0 p-0 a11y-toolbox">
      <li>
        <button className={classNames("btn a11y-btn", {"a11y-btn--active": currentTheme === "default"})}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Domyślny motyw graficzny"
                data-bs-custom-class="di-tooltip"
                onClick={() => {
                  hideTooltips();
                  onSetTheme("default");
                }}>
          <FontAwesomeIcon icon={faSun} aria-hidden={true}/>
          <span className="visually-hidden">Włącz domyślny motyw graficzny</span>
        </button>
      </li>
      <li>
        <button className={classNames("btn a11y-btn", {"a11y-btn--active": currentTheme === "mono"})}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Czarno-biały motyw graficzny"
                data-bs-custom-class="di-tooltip"
                onClick={() => {
                  hideTooltips();
                  onSetTheme("mono");
                }}>
          <FontAwesomeIcon icon={faMoon} aria-hidden={true}/>
          <span className="visually-hidden">Włącz czarno-biały motyw graficzny</span>
        </button>
      </li>
      <li>
      <button className={classNames("btn a11y-btn", {"a11y-btn--active": currentTheme === "hicontrast"})}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title="Kontrastowy motyw graficzny"
              data-bs-custom-class="di-tooltip"
              onClick={() => {
                hideTooltips();
                onSetTheme("hicontrast");
              }}>
        <FontAwesomeIcon icon={faCircleHalfStroke} aria-hidden={true}/>
        <span className="visually-hidden">Włącz kontrastowy motyw graficzny</span>
      </button>
      </li>
    <li className="a11y-toolbox__separator" aria-hidden={true}>|</li>
    <li>
      <button className={"btn a11y-btn"}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title="Zwiększ rozmiar tekstu"
              data-bs-custom-class="di-tooltip"
              onClick={() => {
                fontUp();
                hideTooltips();
              }}>
        <FontAwesomeIcon icon={faPlusCircle} aria-hidden={true}/>
        <span className="visually-hidden">Zwiększ rozmiar tekstu</span>
      </button>
    </li>
    <li>
      <button className={"btn a11y-btn"}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title="Ustaw domyślny rozmiar tekstu"
              data-bs-custom-class="di-tooltip"
              onClick={() => {
                hideTooltips();
                fontDefault();
              }}>
        <span className="a11y-toolbox__font-reset" aria-hidden={true}>A</span>
        <span className="visually-hidden">Ustaw domyślny rozmiar tekstu</span>
      </button>
    </li>
    <li className="a11y-toolbox__btn-wrapper"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="di-tooltip"
            data-bs-title="Zmniejsz rozmiar tekstu">
        <button className={"btn a11y-btn"}
                disabled={fontSizeFloat <= 0.75}
                onClick={() => {
                  hideTooltips();
                  fontDown();
                }}
        >
        <FontAwesomeIcon icon={faMinusCircle} aria-hidden={true}/>
        <span className="visually-hidden">Zmniejsz rozmiar tekstu</span>
      </button>
    </li>
    
    <li className="a11y-toolbox__separator d-none d-md-flex" aria-hidden={true}>|</li>
    <div className="d-none d-md-flex align-items-center a11y-toolbox__app-size">
      <Link className={"btn a11y-btn"}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title="Pomoc"
              data-bs-custom-class="di-tooltip"
              to="/pomoc"
              onClick={() => hideTooltips()}>
        <FontAwesomeIcon icon={faQuestionCircle} aria-hidden={true}/>
        <span className="visually-hidden">Pokaż pomoc</span>
      </Link>
    </div>
  </menu>
  <menu className="d-flex align-items-center m-0 p-0 a11y-toolbox">
    <li className="d-none d-md-flex align-items-center a11y-toolbox__app-size">
      <button className={"btn a11y-btn"}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title={maximized ? `Zmniejsz okno` : `Zwiększ okno`}
              data-bs-custom-class="di-tooltip"
              onClick={() => {
                hideTooltips();
                dispatch(setIsMaximized(!maximized));
              }}
      >
        <FontAwesomeIcon icon={maximized ? faWindowMinimize : faWindowMaximize} aria-hidden={true}/>
        <span className="visually-hidden">{maximized ? `Zmniejsz okno` : `Zwiększ okno`}</span>
      </button>
    </li>
  </menu>
    </>
  );

}